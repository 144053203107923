<template>
  <div>
    <DashboardWidgetLayout widget-id="coordinators-offer-dashboard-full"
      widget-title="Offer Handling"
      :widget-icon="['fas', 'user-group']">
      <template #dashboardSidebarNav>
        <a href=""
          class="nav-link card-header-btn py-0 dashboard-sidenav-link"
          @click.prevent="openColumnConfigModal">
          <span style="padding-right: 0.5rem">Customize Columns</span>
        </a>
      
        <template v-if="hasPermission('internal_dashboard_unfinished_business')">
          <router-link :to="{ name: 'internal-dashboard-unfinished-business' }"
            class="nav-link card-header-btn py-0 dashboard-sidenav-link">
            <span style="padding-right: 0.5rem">Unfinished Business</span>
          </router-link>
        </template>
      </template>
      <template #filters>
        <div class="row">
          <MultiSelectInput v-model="selectedStatus"
            name="status"
            label="Status:"
            :options="[ {id:'', name:'All'}, ...statusList ]"
            label-key="name"
            value-key="id"
            col-style="form-group-standard-column" />

          <MultiSelectInput v-model="selectedTeam"
            name="team"
            label="Team:"
            :options="[ {id:'', name:'All'}, ...teamsList ]"
            label-key="name"
            value-key="id"
            col-style="form-group-standard-column" />

          <div class="form-group-standard-column form-group-date-time">
            <div>
              <label for="from_date">
                <span>Offer Date From:</span>
              </label>
              <input id="from_date" ref="from_date" v-model="dateFrom" class="form-control" type="date">
            </div>
            <div>
              <label for="from_time">
                <span>Offer Time From:</span>
              </label>
              <input id="from_time" ref="from_time" v-model="fromTime" class="form-control" type="time">
            </div>
          </div>
          <div class="form-group-standard-column form-group-date-time">
            <div>
              <label for="to_date">
                <span>Offer Date To:</span>
              </label>
              <input id="to_date" ref="to_date" v-model="dateTo" class="form-control" type="date">
            </div>
            <div>
              <label for="to_time">
                <span>Offer Time To:</span>
              </label>
              <input id="to_time" ref="to_time" v-model="toTime" class="form-control" type="time">
            </div>
          </div>
          <div class="form-group-standard-column">
            <label for="search">
              <span>Keywords:</span>
            </label>
            <input id="search" v-model="searchString" type="text" class="form-control">
          </div>
          <div class="form-group-standard-column">
            <p class="p-label">
              Include Dismissed
            </p>
            <div class="form-check form-check-inline">
              <input id="include_dismissed"
                v-model="showDismissed"
                aria-labelledby="include_dismissed"
                type="checkbox" class="form-check-input">
              <label for="include_dismissed" class="form-check-label">
                <span>Yes</span>
              </label>
            </div>
          </div>
          <div class="form-group-standard-column">
            <p class="p-label">
              Show Cases Only?
            </p>
            <div class="form-check form-check-inline">
              <input id="show_cases_only"
                v-model="showCasesOnly"
                aria-labelledby="show_cases_only"
                type="checkbox" class="form-check-input">
              <label for="show_cases_only" class="form-check-label">
                <span>Yes</span>
              </label>
            </div>
          </div>
        </div>
        <div class="sub-divider" />
        <div class="save-toolbar-column">
          <button class="btn btn-success" @click="filterRecords()">
            Search
          </button>
          <button class="btn btn-outline-secondary" @click="clearFilters()">
            Clear Filters
          </button>
        </div>
      </template>
      <template #content>
        <PortalTable table-id="plugin-dashboard"
          mode="remote"
          :total-records="totalRecords"
          :page="page"
          :page-count="pageCount"
          :table-data="recordList"
          :loading="loading"
          @on-sort-change="onSortChange"
          @on-page="onPortalPage"
          @on-sort="onPortalSort">
          <template #columns>
            <Column v-if="showColumn('donor.code')" field="donor.code" header="Donor" style="min-width: 70px;">
              <template #body="slotProps">
                <a target="_blank" :href="linkToOffer(slotProps.data)" class="table-link">{{ slotProps.data.donor.code }}</a>
              </template>
            </Column>
            <Column v-if="showColumn('organ.organ_group.name')"  field="organ.organ_group.name" header="Organ" style="min-width: 80px;" />
            <Column v-if="showColumn('code')" field="code" header="Match" style="min-width: 80px;" />
            <Column v-if="showColumn('transplant_center.code')" field="transplant_center.code" header="TC" style="min-width: 70px;" />
            <Column v-if="showColumn('donor.opo.code')" field="donor.opo.code" header="OPO" style="min-width: 70px;" />
            <Column v-if="showColumn('donor.age')" field="donor.age" header="Donor Age" style="min-width: 70px;" />
            <Column v-if="showColumn('bd_dcd')" field="bd_dcd" header="BD/DCD" style="min-width: 60px;">
              <template #body="slotProps">
                {{ slotProps.data.donor.dcd_criteria_meets ? 'DCD' : 'BD' }}
              </template>
            </Column>
            <Column v-if="showColumn('donor.or_datetime')" field="donor.or_datetime" header="OR Time" sortable style="min-width: 160px;">
              <template #body="slotProps">
                {{ slotProps.data.donor.or_datetime ? (formatDateTime(slotProps.data.donor.or_datetime) + ' ' + slotProps.data.donor.provider_informations[0].time_zone) : 'N/A' }}
              </template>
            </Column>
            <Column v-if="showColumn('donor.cross_clamp_datetime')" field="donor.cross_clamp_datetime" header="Cross Clamp Time" sortable style="min-width: 160px;">
              <template #body="slotProps">
                {{ slotProps.data.donor.cross_clamp_datetime ? (formatDateTime(slotProps.data.donor.cross_clamp_datetime) + ' ' + slotProps.data.donor.provider_informations[0].time_zone) : 'N/A' }}
              </template>
            </Column>
            <Column v-if="showColumn('offer_date')" field="offer_date" header="Elapsed Time" sortable style="min-width: 160px;">
              <template #body="slotProps">
                {{ timeFromDate(slotProps.data.offer_date) }}
              </template>
            </Column>
            <Column v-if="showColumn('acceptancelist_page_visited_at')" field="acceptancelist_page_visited_at" header="Last Update" sortable style="min-width: 170px;">
              <template #body="slotProps">
                {{ formatDate(slotProps.data.acceptancelist_page_visited_at || slotProps.data.offer_date) }}
              </template>
            </Column>
            <Column v-if="showColumn('seq')" field="seq" header="Seq." sortable style="min-width: 80px;">
              <template #body="slotProps">
                {{ (slotProps.data.match_effective_value && slotProps.data.match_effective_value.effective_seq) ? slotProps.data.match_effective_value.effective_seq : 'N/A' }}
              </template>
            </Column>
            <Column v-if="showColumn('rank')" field="rank" header="Rank" sortable style="min-width: 80px;">
              <template #body="slotProps">
                {{ (slotProps.data.match_effective_value && slotProps.data.match_effective_value.effective_rank) ? slotProps.data.match_effective_value.effective_rank : 'N/A' }}
              </template>
            </Column>
            <Column v-if="showColumn('status.name')" field="status.name" header="Status" style="min-width: 140px;" />
            <Column v-if="showColumn('next_step')" field="next_step" header="Next Step" style="min-width: 130px;">
              <template #body="slotProps">
                <span v-if="slotProps.data.status">
                  {{ slotProps.data.status.next_step }} {{ slotProps.data.extra_next_step == null ? '' : `+ ${slotProps.data.extra_next_step.name}` }}
                  <span v-if="slotProps.data.extra_next_step">
                    <span v-if="slotProps.data.extra_next_step.identifier == 'bypass' && slotProps.data.bypassed_offer">
                      {{
                        ` ${slotProps.data.bypass_codes.includes(slotProps.data.bypassed_offer.decline_code_1) ? `${slotProps.data.bypassed_offer.decline_code_1},` : ''}
                            ${slotProps.data.bypass_codes.includes(slotProps.data.bypassed_offer.decline_code_2) ? slotProps.data.bypassed_offer.decline_code_2 : ''}
                            at Seq # ${slotProps.data.bypassed_offer.seq}`
                      }}
                    </span>
                    <span v-else-if="slotProps.data.extra_next_step.identifier == 'under_evaluation'">
                      {{ slotProps.data.under_evaluation_offer.seq }}
                    </span>
                  </span>
                </span>
              </template>
            </Column>
            <Column v-if="showColumn('coordinator_note.note')" field="coordinator_note.note" header="Most recent Coordinator Note" style="min-width: 140px;" />
            <Column class="btn-2-icon-group" style="min-width:130px;">
              <template #body="slotProps">
                <template v-if="slotProps.data.dismissed">
                  <a href="#" title="Activate" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="performMatchAction('activate',slotProps.data)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', 'circle-plus']" /><span class="mobile-action">Activate</span>
                  </a>
                </template>
                <template v-else>
                  <a href="#" title="Dismiss Offer" class="action btn btn-fill btn-outline-danger mx-2" @click.prevent="performMatchAction('dismiss',slotProps.data)">
                    <font-awesome-icon class="desktop-action" :icon="['far', 'circle-xmark']" /><span class="mobile-action">Dismiss Offer</span>
                  </a>
                </template>

                <template v-if="slotProps.data.case">
                  <a href="#" :title="removeCaseText(slotProps.data)" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="performMatchAction('unmake_case', slotProps.data)">
                    <font-awesome-icon v-if="slotProps.data.performing_action_unmake_case" :icon="['fas', 'fa-spinner']" class="fa-spin" />
                    <font-awesome-icon v-else class="desktop-action" :icon="['fas', 'circle-minus']" /><span class="mobile-action">{{ removeCaseText(slotProps.data) }}</span>
                  </a>
                </template>
                <template v-else>
                  <a href="#" title="Make Case" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="performMatchAction('make_case', slotProps.data)">
                    <font-awesome-icon v-if="slotProps.data.performing_action_make_case" :icon="['fas', 'fa-spinner']" class="fa-spin" />
                    <font-awesome-icon v-else class="desktop-action" :icon="['fas', 'house-medical-flag']" /><span class="mobile-action">Make Case</span>
                    
                  </a>
                </template>
              </template>
            </Column>
            <Column class="btn-3-icon-group">
              <template #body="slotProps">
                <div class="d-flex">
                  <a v-if="hasPermission('internal_match_decline_offer')" href="#" title="Decline" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="showModalFor(slotProps.data, ActionModals.DECLINE)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', 'ban']" /><span class="mobile-action">Decline</span>
                  </a>
  
                  <a href="#" title="Details" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="showModalFor(slotProps.data, ActionModals.DONOR_DETAILS)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', 'file-lines']" /><span class="mobile-action">Details</span>
                  </a>
  
                  <a href="#" :title="!slotProps.data.coordinator_note ? `Add Notes` : `Notes`" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="showModalFor(slotProps.data, ActionModals.NOTES)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', !slotProps.data.coordinator_note ? 'plus-square' : 'comment-alt']" /><span class="mobile-action">Notes</span>
                  </a>
                  <div v-if="hasPermission('internal_match_communication_templates') || hasPermission('internal_match_manage_alternative_notifications')" id="plugin-dropdown" class="dropdown">
                    <button id="dropdownMenu2" class="btn btn-outline-primary dropdown-toggle" type="button"
                      data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <font-awesome-icon :icon="['fas', 'caret-down']" /><span class="sr-only">click to select</span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button v-if="hasPermission('internal_match_communication_templates')" class="dropdown-item" type="button" @click="goToTemplate(slotProps.data)">
                        Comms Templates
                      </button>
                      <button v-if="hasPermission('internal_match_manage_alternative_notifications')" class="dropdown-item" type="button" @click="showModalFor(slotProps.data, ActionModals.NOTIFICATIONS)">
                        Notifications
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </Column>
          </template>
        </PortalTable>
      </template>
    </DashboardWidgetLayout>
    <ColumnConfigModal ref="columnConfigModal"
      v-model="selectedColumns"
      column-key="pluginDashboard"
      config-id="plugin-dashboard"
      :option-defaults="getColumnDefaults"
      :options="columnOptions"
      @close="closeConfigModal()"
      @change-selected="onChangeSelectedColumns" />

    <!-- Render modal component dynamically -->
    <component :is="requestedModal" 
      v-if="requestedModal" 
      :ref="requestedModal"
      :match-id="selectedMatch && selectedMatch.id"
      :donor-id="selectedMatch && selectedMatch.donor.id"
      @close="modalClosed()" 
      @note-updated="reloadRowData()" />
  </div>
</template>

<script lang="js">
import DeclineModal from "@/src/components/buckeye-coordinator-area/modals/DeclineModal.vue";
import NotesModal from "@/src/components/buckeye-coordinator-area/modals/CoordinatorNotesModal.vue";
import DonorDetailsModal from "@/src/components/buckeye-coordinator-area/modals/DonorDetailsModal.vue";
import NotificationsModal from "@/src/components/buckeye-coordinator-area/modals/NotificationsModal.vue";
import beApiClient from "@/src/be-api-client";
import DashboardWidgetLayout from "~/src/components/shared/DashboardWidgetLayout.vue";
import {APIRoute, EP} from "~/src/endpoints";
import {useAppCoreStore} from "@/src/stores/app-core";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import ColumnConfigModal from "~/src/components/shared/column-config/ColumnConfigModal.vue";
import { formatDateTime } from "@/src/date-helpers";


const ActionModals = {
  DECLINE: 'decline-modal',
  NOTES: 'notes-modal',
  DONOR_DETAILS: 'donor-details-modal',
  NOTIFICATIONS: 'notifications-modal'
}

export default {
  components: {
    DeclineModal,
    NotesModal,
    NotificationsModal,
    DonorDetailsModal,
    DashboardWidgetLayout,
    Column,
    PortalTable,
    MultiSelectInput,
    ColumnConfigModal,
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  data() {
    return {
      ActionModals,
      recordList: [],
      teamsList: [],
      donor_details: null,
      recipient_details: null,
      searchString: '',
      currentSortField: '',
      currentSortType: '',
      selectedMatch: null,
      selectedTeam: '',
      selectedStatus: '',
      dateFrom: '',
      fromTime: '',
      dateTo: '',
      toTime: '',
      pageCount: 1,
      totalRecords: 0,
      version: '',
      page: 1,
      isModalOpen:false,
      loading: false,
      statusList: [],
      showDismissed: false,
      showCasesOnly: false,
      requestedModal: null,
      selectedColumns: [],
      columnOptions:[
        { label: 'Donor', field: 'donor.code', default: true },
        { label: 'Organ', field: 'organ.organ_group.name', default: true },
        { label: 'Match', field: 'code', default: true },
        { label: 'TC', field: 'transplant_center.code', default: true },
        { label: 'OPO', field: 'donor.opo.code', default: true },
        { label: 'Donor Age', field: 'donor.age', default: false },
        { label: 'BD/DCD', field: 'bd_dcd', default: true },
        { label: 'OR Time', field: 'donor.or_datetime', default: false },
        { label: 'Cross Clamp Time', field: 'donor.cross_clamp_datetime', default: false },
        { label: 'Elapsed Time', field: 'offer_date', default: true },
        { label: 'Last Update', field: 'acceptancelist_page_visited_at', default: true },
        { label: 'Seq.', field: 'seq', default: true },
        { label: 'Rank', field: 'rank', default: true },
        { label: 'Status', field: 'status.name', default: true },
        { label: 'Next Step', field: 'next_step', default: true },
        { label: 'Most recent Coordinator Note', field: 'coordinator_note.note', default: false },
      ],
      formatDateTime: formatDateTime
   }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    getColumnDefaults() {
      return this.columnOptions.filter(option => option.default).map(option => option.field);
    }
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        to: { name: 'internal-dashboards-root' },
        text: 'Dashboards'
      },
      {
        text: 'Offers Dashboard'
      }
    ]);
    let params = new URLSearchParams(window.location.search);
    this.page = params.get('page') || 1
    this.searchString = params.get('search') || ''
    this.selectedStatus = params.get('status') || ''
    this.selectedTeam = params.get('team') || ''
    this.showDismissed = params.get('show_dismissed') === 'true'
    this.showCasesOnly = params.get('show_cases_only') === 'true'
    this.selectedColumns = this.buildColumnsFilter();

    try {
      this.getRowData()
    } catch(error) {
      console.log(error)
    }
  },
  methods: {
    showColumn(key) {
      return this.selectedColumns.includes(key);
    },
    onChangeSelectedColumns(newValues) {
      this.selectedColumns = newValues;
    },
    buildColumnsFilter() {
      // get selected columns from user preferences or defaults
      return this.appStore.currentUser.getColumnPreferences('pluginDashboard', this.getColumnDefaults);
    },
    openColumnConfigModal() {
      this.$refs.columnConfigModal.showModal();
    },
    hasPermission(key) {
      if(!this.user || !this.userPermissions) return false;
      return this.userPermissions[key] && this.userPermissions[key].read == true ? true : false;
    },
    showModalFor(match, modalType) {
      this.selectedMatch = match;
      this.requestedModal = modalType;
      this.$nextTick(() => {
        let modal = this.$refs[modalType];
        if(!modal) {
          alert(`No modal found for ${modalType}`);
          return;
        }
        modal.showModal();
      });
    },
    modalClosed() {
      this.selectedMatch = null;
    },
    clearFilters() {
      this.searchString = ''
      this.selectedStatus = ''
      this.selectedTeam = ''
      this.showDismissed = false
      this.showCasesOnly = false
      this.dateFrom = ''
      this.fromTime = ''
      this.toTime = ''
      this.dateTo = ''
      this.$refs.from_date.value = ''
      this.$refs.from_time.value = ''
      this.$refs.to_date.value = ''
      this.$refs.to_time.value = ''

      this.getRowData(true)
    },
    performMatchAction(action, match) {
      match[`performing_action_${action}`] = true; 
      const endpoint = APIRoute(EP.matches[action],{match_id: match.id});
      if(!endpoint) alert(`Unable to perform ${action} on a match. Please check!`);
      beApiClient.patch(endpoint).then((response) => {
        this.getRowData()
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        match[`performing_action_${action}`] = false;
      });
    },
    formatDate(dateString) {
      if (!dateString) return null;
      const date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    filterRecords() {
      this.getRowData(true);
    },
    getRowData(reset = false) {
      const _vm = this
      if (reset) this.page = 1;

      const offer_date_from = this.dateFrom && this.fromTime ? new Date(`${this.dateFrom}T${this.fromTime}`) : this.dateFrom ? this.dateFrom : '';
      const offer_date_to = this.dateTo && this.toTime ? new Date(`${this.dateTo}T${this.toTime}`) : this.dateTo ? this.dateTo : '';
      const params = {
        page: this.page,
        search: this.searchString.trim(),
        status: this.selectedStatus,
        show_dismissed: this.showDismissed,
        team: this.selectedTeam,
        date_from: offer_date_from,
        date_to: offer_date_to,
        sortField: this.currentSortField,
        sortType: this.currentSortType,
        show_cases_only: this.showCasesOnly
      }

      // Get data
      this.loading = true;
      const url = APIRoute(EP.coordinator_dashboard.plugin);
      beApiClient.get(url, {
        headers: {'Accept': 'application/json'},
        params: params })
      .then((response) => {
        _vm.totalRecords = response.data.total_records
        _vm.recordList = response.data.records
        _vm.pageCount = response.data.page_count
        _vm.teamsList = response.data.teams.sort((a, b) => { return a.name.localeCompare(b.name) })
        _vm.statusList = response.data.statuses
        _vm.loading = false;
      })
    },
    timeFromDate(date) {
      let daysCalc = 1000 * 60 * 60 * 24
      let milliseconds = new Date() - new Date(date)
      let days = Math.floor(milliseconds / daysCalc);
      milliseconds -= days * daysCalc

      let hours = Math.floor(milliseconds / (daysCalc / 24));
      milliseconds -= hours * (daysCalc / 24)

      let minutes = Math.floor(milliseconds / (daysCalc / (24 * 60)));
      milliseconds -= minutes * (daysCalc / (24 * 60))

      let seconds = Math.floor(milliseconds / (daysCalc / (24 * 60 * 60)));

      return `${days > 0 ? `${days}d, ` : ''}${hours > 0 ? `${hours}h, ` : ''}${minutes > 0 ? `${minutes}m, ` : ''}${seconds > 0 ? `${seconds}s` : ''}`
    },
    getModalData(record) {
      let offers = [record]
      offers = offers.concat(record.offers_ordered_by_seq.filter((offer) => {
        return offer.decline_code_1 != 0 || offer.decline_code_2 != 0
      }))
      this.selectedRow = {
        donor: record.donor.code,
        match: record.id,
        organ: record.organ.name,
        tc: record.transplant_center.code,
        offer_date: record.offer_date,
        offers: offers
      }
    },
    closeModal() {
      this.clearDetails();
    },
    clearDetails() {
      this.donor_details = null;
      this.recipient_details = null;
    },
    onSortChange(params) {
      if (params == null) {
        this.currentSortField = undefined;
        this.currentSortType = undefined;
        this.getRowData(true)
      }
    },
    onPortalSort(params) {
      if (!params.sortField) { return false; }
      this.currentSortField = params.sortField;
      switch(params.sortOrder) {
        case 1:
          this.currentSortType = 'asc';
          break;
        case -1:
          this.currentSortType = 'desc';
          break;
      }
      this.getRowData();
    },
    onPortalPage(page) {
      this.page = page;
      this.getRowData();
    },
    reloadRowData() {
      this.getRowData();
    },
    linkToOffer(row) {
      return row.email_url && row.email_url.length > 0 ? row.email_url : 'https://auth.unos.org/Login?redirect_uri=https%3A%2F%2Fportal.unos.org';
    },
    goToTemplate(data) {
      const donor = data.donor.id;
      const match = data.id;
      const txc = data.transplant_center.id
      const organGroup = data.organ.organ_group_id

      this.$router.push({ 
        name: 'generate-instance', 
        params: { 
          donor: donor, 
          match: match, 
          txc: txc, 
          organGroup: organGroup }
      });
    },
    closeConfigModal(){
      this.selectedColumns = this.buildColumnsFilter();
    },
    removeCaseText(match) {
      let text = "Remove from Case"
      if(match.omnilife_case_status && match.omnilife_case_status.last_integration_status) {
        if(match.omnilife_case_status.last_integration_status === 'error') {
          text += " (OL: Failed)"
        } else {
          text += " (OL: Created)"
        }
      }

      return text;
    }
  }
}
</script>
